$borderColor: #DFDFDF
$lightBorderColor: #EDEDED
$blueBorderColor: #B2DDFD
$tableBorderColor: 1px solid rgba(0, 0, 0, 0.16)

$lightBackgroundColor: #FFFFFF
$alternateBackgroundColor: #F7F7F7
$alternateDarkBackgroundColor: #E8E8E8
$darkBackgroundColor: #F4F6F9
$headerBackgroundColor: #E8EAEC
$primaryBackgroundColor: #EEF8FF
$greenBackgroundColor: #BAE2B8
$greenBackgroundColorLight: #DBF3D9
$blueBackgroundColor: #B7E1FF
$redBackgroundColor: #FFB6B6
$redBackgroundColorLight: #FFD8D8
$yellowBackroundColor: #FDEB9C
$warningColor: #e39410

$placeholderColor: #9B9B9B
$fontColor: #191919
$lightFontColor: #8A8B98
$darkFontColor: #000000
$primaryFontColor: #2A303C

$whiteColor: #FFFFFF
$blueColor: #1D96EE
$mediumBlue: #1980CA
$brightBlue: #1778BE
$redColor: #E70000
$orangeColor: #F39C12
$yellowColor: #F1C40F
$greenColor: #1E7A67
$lightGreenColor: #73CD1F
$blackColor: #191919
$amberColor: #FFBF00
$lightGray: #F2F2F2
$softGray: #C1C1C1
$steelGray: #6E7787

$filterGreen: #249B9B
$filterGreenBackground: #D7F7F3

$filterBlueBackround: #E0F2FF

$filterRedBackround: #FFE4E4

$filterPurple: #921DEE
$filterPurpleBackground: #EAE4FF
