$mt-values: 5, 10, 15, 20, 25, 30
@each $value in $mt-values
  .mt-#{$value}
    margin-top: #{$value}px

$mb-values: 5, 10, 15, 20, 25, 30
@each $value in $mb-values
  .mb-#{$value}
    margin-bottom: #{$value}px

$mr-values: 5, 10, 15, 20, 25, 30
@each $value in $mr-values
  .mr-#{$value}
    margin-right: #{$value}px

$ml-values: 5, 10, 15, 20, 25, 30
@each $value in $ml-values
  .ml-#{$value}
    margin-left: #{$value}px


$padding-values: 5, 10, 15, 20, 25, 30
@each $value in $padding-values
  .p-#{$value}
    padding: #{$value}px

$gap-values: 5, 10, 15, 20, 25, 30
@each $value in $gap-values
  .gap-#{$value}
    gap: #{$value}px

$grid-cols-values: 2, 3, 4
@each $value in $grid-cols-values
  .grid-cols-#{$value}
   grid-template-columns: repeat( #{$value}, minmax(0, 1fr))

$font-weight-values: 400, 500, 600, 700, 800, 900
@each $value in $font-weight-values
  .font-weight-#{$value}
   font-weight: #{$value}


.absolute
  position: absolute

.relative
  position: relative

.block
  display: flex

.flex
  display: flex

.grid
  display: grid

.flex-nowrap
  flex-wrap: nowrap

.flex-wrap
  flex-wrap: wrap

.flex-row
  flex-direction: row

.flex-col
  flex-direction: column

.justify-start
  justify-content: flex-start

.justify-end
  justify-content: flex-end

.justify-center
  justify-content: center

.justify-between
  justify-content: space-between

.justify-items-start
  justify-items: start

.justify-items-end
  justify-items: end

.justify-items-center
  justify-items: center

.justify-self-start
  justify-self: start

.justify-self-end
  justify-self: end

.justify-self-center
  justify-self: center

.content-start
  align-content: flex-start

.content-end
  align-content: flex-end

.content-between
  align-content: space-between

.content-center
  align-content: center

.items-start
  align-items: flex-start

.items-end
  align-items: flex-end

.items-center
  align-items: center

.self-start
  align-self: flex-start

.self-end
  align-self: flex-end

.self-center
  align-self: center

// cursors

.pointer
  cursor: pointer

//  width
.w-full
  width: 100%

.w-fit
  width: fit-content

.w-max
  width: max-content

// typography

.overflow-wrap
  overflow-wrap: break-word

