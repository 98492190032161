@use 'variables' as *

.tag
  padding: 1px 10px 2px 10px
  background: $alternateBackgroundColor
  border-radius: 5px
  color: $primaryFontColor
  font-weight: 700
  line-height: 16px
  font-size: 10px
  width: fit-content
  &.active
    color: $whiteColor
    background: $blueColor
  &.with-border
    border: 1px solid rgba(16, 35, 74, 0.08)
