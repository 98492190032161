@use 'variables' as *

.custom-button
  height: 40px
  padding: 9px 12px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 8px
  font-weight: 700
  font-size: 13px
  cursor: pointer
  &.primary
    background: $blueColor
    color: $whiteColor
    border: 1px solid $blueColor
    &:hover
      background: $mediumBlue
    &:disabled
      background: $softGray
  &.secondary
    background: $whiteColor
    color: $blueColor
    border: 1px solid $blueColor
    &:hover
      background: $lightGray
    &:disabled
      opacity: 0.4
      color: $blueColor !important
      background: $whiteColor !important
      border: 1px solid $blueColor !important
  &.cancel
    background: $whiteColor
    color: $redColor
    border: 1px solid $redColor
    &:hover
      background: $lightGray
    &:disabled
      opacity: 0.4
      background: $whiteColor !important
      color: $redColor !important
      border: 1px solid $redColor !important
  &.small
    padding: 8px
    height: 32px
  &.line
    background: $whiteColor
    color: $blueColor
    border: none
    &:hover
      color: $brightBlue
    &:disabled
      opacity: 0.4
      background: $whiteColor !important
      color: $blueColor !important
      border: none !important
    &.dark
      color: $steelGray
  & .icon
    width: 16px
    display: flex
    align-items: center
    justify-content: center
    &.right
      margin-right: 10px
    &.left
      margin-left: 10px
