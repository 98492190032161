@use 'variables' as *
.tabs-list
  list-style: none
  padding: 0
  margin: 0
  display: flex

.tab
  padding: 15px 15px 10px
  border-top-left-radius: 8px
  border-top-right-radius: 8px
  cursor: pointer
  display: block
  color: $primaryFontColor
  font-weight: 400
  font-size: 14px
  &.active
    background-color: $lightBackgroundColor
    font-weight: 700

.tab-content
  border-bottom-left-radius: 8px
  border-bottom-right-radius: 8px
  border-top-right-radius: 8px
  background-color: $lightBackgroundColor
  padding: 15px
  &.with-radius
    border-top-left-radius: 8px
